import service from "./FetchInterceptor";
import {baseUrl, usersUrls} from "../util/api_constant";

const HEADER: any = {
	'public-request' : 'true',
};

class AuthService{
	public static login(email: string, password: string){
		return service({
			url: baseUrl + '/auth',
			method: 'post',
			data: {username: email, password: password}
		}).then(response => response)
	}
	public static signup(firstName: string, lastName: string, email: string, password: string) {
		console.log( {
			firstName: firstName,
			lastName : lastName,
			roles: [
				{
					id: 2,
					name: "MEMBER",
				}
			],
			username: email,
			password: password
		}
		)
		return service({
			url: usersUrls.base,
			method: 'POST',
			headers: HEADER,
			data: {
				firstName: firstName,
				lastName : lastName,
				roles: [
					{
						id: 2,
						name: "MEMBER",
					}
				],
				username: email,
				password: password
			}
		})
	}
}

export default AuthService
