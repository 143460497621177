import React from "react"
import RegisterHeader from "./RegisterHeader";
import RegisterForm from "./RegisterForm";

const RegisterContent = ({}: any) => {

    return (
        <>
            <div className="content-wrapper">
                <RegisterHeader />
                <RegisterForm />
            </div>
        </>
    )
}

export default RegisterContent
