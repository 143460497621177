import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {getConnected, loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import RegisterContent from "../../components/register/RegisterContent";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";


const Register = ({step} : any) => {
    const { t } = useTranslation()
    let connected = getConnected()
    const [user, setUser] = useState(connected ? connected : null)
    useEffect(() => {
        if(user){
            alert(`${user.firstName}, ${t('alreadyConnected')}`)
            window.location.replace("/")
        }
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
    }, [user])

    return (
        <>
            <Helmet>
                <title>OHADARB | {t('signUp')} </title>
            </Helmet>
            <Layout title={`OHADARB | ${t('signUp')}`}>
                <RegisterContent />
            </Layout>
        </>

    );
}
export default Register;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
