import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import AuthService from "../../services/AuthService";
import ReCAPTCHA from "react-google-recaptcha";
import {AUTH_TOKEN_NAME, AUTH_TOKEN_PREFIX, AUTH_TOKEN_SUFFIX, CONNECTED} from "../../services/FetchInterceptor";
import {GATSBY_RECAPTCHA_SITE_KEY} from "../../services/awsS3Services";

const initialesValues = {firstName: "", lastName: "", email: "", password: "", confirmPassword: ""};

const RegisterForm = ({}: any) => {

    const {t, i18n} = useTranslation()
    const [eye, setEye] = useState(false);
    const [eye2, setEye2] = useState(false);
    const [formValues, setFormValues] = useState(initialesValues);
    const [successSubcriptionModal, setSuccessSubcriptionModal] = useState(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorSubmitted, setErrorSubmitted] = useState("");
    const showPwd = () => setEye(!eye);
    const showPwd2 = () => setEye2(!eye2);
    // You will need to declare a ref to be able to determine if the user has succesfully demonstrated that it is not a robot.
    const reCaptchaElement: any = useRef<any>(null);


    const submit = () => {
        setLoading(true);
        const {firstName, lastName, email, password, confirmPassword} = formValues;
        AuthService.signup(firstName?.trim(), lastName?.trim(), email?.trim(), password?.trim()).then(
            (response) => {
                let token = response.headers['x-auth-token'];
                if (token) {
                    if (localStorage) {
                        const {
                            id,
                            firstName,
                            lastName,
                            email,
                            roles,
                            // subscriptions,
                            // phoneNumber,
                            // countryCode
                        } = response.data;
                        console.log(response.data)
                        if (id) {
                            openSuccessModal();
                        }
                        localStorage.setItem(AUTH_TOKEN_NAME, AUTH_TOKEN_PREFIX + token + AUTH_TOKEN_SUFFIX);
                        localStorage.setItem(CONNECTED, JSON.stringify({id, firstName, lastName, email, roles}));
                    }
                //     const pathName = location.href;
                //     if (pathName.includes('returnUrl')) {
                //         const split = pathName.split('returnUrl=');
                //         location.replace(`${split[1]}`);
                //     } else
                //         location.replace('/login');
                }
            }
        ).catch((error: any) => {
            error.response ?
                error.response.status === 400 ? setErrorSubmitted(t('UserErrorMessage')) :
                    setErrorSubmitted(error.response.data.message) :
                setErrorSubmitted(t('serverErrorMessage'))
        }).finally(() => setLoading(false))
    }

    const openSuccessModal = () => {
        const link = document.querySelector("#successModalBtn");
        if (link) {
            link.addEventListener('click', (e) => {
            });
        }
    }

    //input change handler
    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };

    //form submission handler
    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    //form validation handler
    const validate = (values: any) => {
        let errors: any = {};
        const captchaValue = reCaptchaElement.current.getValue()
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        // passwordRegex : at least one digit, lowercase and uppercase letter, between 4 and 8 characters
        const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{4,16}$/
        if (!values.firstName) {
            errors.firstName = t('cantBeEmpty')
        }
        if (!values.lastName) {
            errors.lastName = t('cantBeEmpty')
        }
        if (!values.email) {
            errors.email = t('cantBeEmpty')
        } else if (!regex.test(values.email)) {
            errors.email = t('emailErrorMessage')
        }
        if (!values.password) {
            errors.password = t('cantBeEmpty')
        }else if (values.confirmPassword.length < 4 && values.confirmPassword.length > 8) {
            errors.confirmPassword = t('passwordErrorMessage');
        }
        if(!passwordRegex.test(values.password)){
            errors.password = t('passwordErrorMessageRegex');
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = t('cantBeEmpty')
        } else if (values.confirmPassword.length < 4 && values.confirmPassword.length > 8) {
            errors.confirmPassword = t('passwordErrorMessage');
        } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = t('confirmErrorMessage');
        } else if (!captchaValue) {
            errors.recaptcha = t('recaptchaErrorMessage')
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submit();
        }
    }, [formErrors]);

    return (
        <>
            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-n20">
                            <div className="card">
                                <div className="card-body p-11 text-center">
                                    <h2 className="mb-3 text-start">OHADARBITRATION | {t('signUp')}</h2>
                                    {errorSubmitted !== "" && (
                                        <div className="" style={{
                                            padding: 3,
                                            marginBottom: 5,
                                            borderRadius: 5,
                                            borderWidth: "1px",
                                            borderColor: "red",
                                            borderStyle: "solid"
                                        }}>
                                            <span
                                                className="error-submit-msg text-red shadow-lg font-monospace fs-md">{errorSubmitted}</span>
                                        </div>
                                    )}
                                    <p className="lead mb-6 text-start">{t('registerMessage')}</p>
                                    <form className="text-start mb-3" onSubmit={handleSubmit} noValidate>
                                        <div className="form-floating mb-4">
                                            <input

                                                name="firstName"
                                                type="text"
                                                className="form-control"
                                                placeholder={t('firstName')}
                                                id="loginFirstName"
                                                value={formValues.firstName}
                                                onChange={handleChange}
                                            />
                                            {formErrors.firstName && (<div
                                                className="error-msg px-2 text-danger">{formErrors.firstName}</div>)}
                                            <label htmlFor="loginFirstName">{t('firstName')}</label>
                                        </div>
                                        <div className="form-floating mb-4">
                                            <input

                                                name="lastName"
                                                type="text"
                                                className="form-control"
                                                placeholder={t('lastName')}
                                                id="loginLastName"
                                                value={formValues.lastName}
                                                onChange={handleChange}
                                            />
                                            {formErrors.lastName && (<div
                                                className="error-msg px-2 text-danger">{formErrors.lastName}</div>)}
                                            <label htmlFor="loginLastName">{t('lastName')}</label>
                                        </div>
                                        <div className="form-floating mb-4">
                                            <input
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Email"
                                                id="loginEmail"
                                                value={formValues.email}
                                                onChange={handleChange}
                                            />
                                            {formErrors.email && (
                                                <div className="error-msg px-2 text-danger">{formErrors.email}</div>)}
                                            <label htmlFor="loginEmail">{t('email')}</label>
                                        </div>
                                        <div className="form-floating password-field mb-4">
                                            <input
                                                name="password"
                                                type={eye ? "text" : "password"}
                                                className="form-control"
                                                placeholder="Password"
                                                id="loginPassword"
                                                value={formValues.password}
                                                onChange={handleChange}
                                            />
                                            <span className="password-toggle" onClick={showPwd}>
                                                <i className={eye ? "uil uil-eye-slash" : "uil uil-eye"}></i>
                                            </span>
                                            {formErrors.password && (<div
                                                className="error-msg px-2 text-danger">{formErrors.password}</div>)}
                                            <label htmlFor="loginPassword">{t('password')}</label>
                                        </div>
                                        <div className="form-floating password-field mb-4">
                                            <input
                                                name="confirmPassword"
                                                type={eye2 ? "text" : "password"}
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                id="loginPasswordConfirm"
                                                value={formValues.confirmPassword}
                                                onChange={handleChange}
                                            />
                                            <span className="password-toggle" onClick={showPwd2}>
                                                <i className={eye2 ? "uil uil-eye-slash" : "uil uil-eye"}></i>
                                            </span>
                                            {formErrors.confirmPassword && (<div
                                                className="error-msg px-2 text-danger">{formErrors.confirmPassword}</div>)}
                                            <label htmlFor="loginPasswordConfirm">{t('confirmPass')}</label>
                                        </div>

                                        <div className="form-floating mb-4">
                                            <ReCAPTCHA
                                                id="recaptcha"
                                                ref={reCaptchaElement}
                                                sitekey={GATSBY_RECAPTCHA_SITE_KEY as string}
                                            />
                                            {formErrors.recaptcha && (
                                                <div
                                                    className="error-msg px-2 text-danger">{formErrors.recaptcha}</div>)}
                                        </div>
                                        <button type="submit"
                                                className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                                                disabled={loading}>
                                            {loading ? t('loading') : t('signUp')}
                                            {/*    icon loader */}
                                            <div className="loader" style={{display: loading ? "block" : "none"}}>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </button>
                                    </form>
                                    <p className="mb-0">{t('haveAnAccount')} <a href="/app/login"
                                                                                className="hover">{t('signIn')}</a></p>
                                    <div className="divider-icon my-4">or</div>
                                    <nav className="nav social justify-content-center text-center">
                                        <a href="#" className="btn btn-circle btn-sm btn-google"><i
                                            className="uil uil-google"></i></a>
                                        <a href="#" className="btn btn-circle btn-sm btn-facebook-f"><i
                                            className="uil uil-facebook-f"></i></a>
                                        <a href="#" className="btn btn-circle btn-sm btn-twitter"><i
                                            className="uil uil-twitter"></i></a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a id="successModalBtn" className="btn btn-primary rounded-pill mx-1 mb-2 mb-md-0"
                   data-bs-toggle="modal"
                   data-bs-target="#modal-signin" style={{display: "none"}}
                ></a>
                <div className="modal fade"
                     id="modal-signin"
                     tabIndex={-1}
                >
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content text-center">
                            <div className="modal-body">
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                <h2 className="mb-3 text-Center">{t('Hello')}, {formValues.firstName} {formValues.lastName}</h2>
                                <h3 className="mb-3 text-Center">{t('creationMessage')}</h3>
                                <p className="lead mb-6 text-center">{t('creationMessage2')}</p>
                                <a className="btn btn-primary rounded-pill mx-1 mb-2 mb-md-0"
                                   href="/app/login"> {t('signIn')}</a>
                                <div className="divider-icon my-4">or</div>
                                <nav className="nav social justify-content-center text-center">
                                    <a href="#" className="btn btn-circle btn-sm btn-google"><i
                                        className="uil uil-google"></i></a>
                                    <a href="#" className="btn btn-circle btn-sm btn-facebook-f"><i
                                        className="uil uil-facebook-f"></i></a>
                                    <a href="#" className="btn btn-circle btn-sm btn-twitter"><i
                                        className="uil uil-twitter"></i></a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}

export default RegisterForm;
